<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>Add BOQ Bonus Rate</h1>
          <p>This will be available on Safety PAL for teams to add to their DRS</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field
            label="Description"
            v-model="bonusRate.description"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field
            label="Units"
            v-model="bonusRate.units"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field
            label="Bonus Rate (£)"
            type="number"
            step="0.01"
            v-model="bonusRate.rate"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field
            label="Material Usage"
            type="number"
            step="0.01"
            v-model="bonusRate.material_usage"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-btn @click="saveBoq">Save</v-btn>
        </v-col>
      </v-row>
      <v-dialog
        v-model="showMessage"
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="orange darken-2"
              text
              @click="$router.push('/admin/boq-bonus-rates/index')"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>  
  </template>
  
  <script>
  import axios from '../../../axios';
  
  export default {
    name: 'AdminBoqBonusRatesAdd',
    computed: {
      token() {
        return this.$store.state.token;
      },
      userid() {
        return this.$store.state.userid;
      },
    },
    data() {
      return {
        bonusRate: {
          description: '',
          units: '',
          rate: 0,
          material_usage: 0,
        },
        message: '',
        showMessage: false,
      };
    },
    methods: {
      saveBoq() {
        axios.post(`/bonusRates/create.json?token=${this.token}`, this.bonusRate)
          .then(() => {
            this.$router.push('/admin/boq-bonus-rates/index');
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
  